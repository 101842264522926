<vdr-dropdown>
    <button class="button-small" vdrDropdownTrigger>
        <clr-icon shape="details" size="12"></clr-icon>
        {{ 'common.details' | translate }}
    </button>
    <vdr-dropdown-menu>
        <div class="entry-dropdown">
            <ng-content></ng-content>
        </div>
    </vdr-dropdown-menu>
</vdr-dropdown>
